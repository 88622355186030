import Grid from "@mui/material/Grid";
import textoIcon from "@assets/svgs/lineasTexto.svg";
import { EsfirusInputTextAreaNative, EsfirusInputTextNative } from "@ui/InputTextNative/EsfirusInputTextNative";
import "./LineasTexto.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateLine } from "@store/slices/lines";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import { Capitulo } from "@models/obra";

function LineasTexto(props: any) {
  const dispatch = useDispatch();
  const lineas = useSelector((state) => (state as any).lines);
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;

  const getChapterOptions = (): any[] => {
    if (!selectedobra.capitulosByPresupuesto) {
      return [];
    }

    return selectedobra.capitulosByPresupuesto?.map((cap: Capitulo) => ({
      ...cap,
      label: cap.label,
      value: cap.Capitulo,
    }));
  };

  return (
    <Grid
      className="line-services-container"
      container
      spacing={1}
      alignItems={"center"}
    >
      <Grid
        className="flex-row"
        item
        xs={12}
        md={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img className="logo mr-10" src={textoIcon} alt="service" />
        <h4>TX</h4>
      </Grid>
      <Grid item xs={12} md={10} container spacing={2}>
        {(selectedobra.SolCapitulo && selectedobra.capitulosByPresupuesto &&
          selectedobra.capitulosByPresupuesto.length !== 0) && (
            <Grid item xs={12} md={4}>
              <label>Capítulo</label>
              <EsfirusSelect
                options={getChapterOptions()}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                disabled={!props.editable}
                value={lineas?.list[props.index]?.chapter?.Capitulo}
                change={(e: any) => {
                  const selectedChapter =
                    selectedobra.capitulosByPresupuesto.find(
                      (el: Capitulo) => el.Capitulo === e
                    );

                  dispatch(
                    updateLine({
                      ...lineas.list[props.index],
                      chapter: selectedChapter,
                    })
                  );
                }}
                placeholder={"Capítulo"}
                modified={!!lineas.list[props.index]?.validationModifications?.datos?.chapter}
              ></EsfirusSelect>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <label>Texto</label>

          <EsfirusInputTextAreaNative
            min={10}
            disabled={!props.editable}
            required={true}
            value={lineas?.list[props.index]?.text}
            change={(e: any) => {
              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  text: e,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.text}
          ></EsfirusInputTextAreaNative>
        </Grid>
      </Grid>
    </Grid>
  );
}

export { LineasTexto };
